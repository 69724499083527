<template>
	<div class="flex flex-col items-start md:block">
		<!-- #TODO: weights not currently supported by api -->
		<!-- <BusinessMenuWeightFilters
			:weights="weights"
		/> -->
		<!-- #TODO: per design, genetics needs to be update to accept an array instead of just single enum -->
		<!-- <BusinessMenuGeneticsFilter
			v-if="menuFilters.genetics?.length"
			:genetics="menuFilters.genetics"
		/> -->
		<!-- #TODO: need BE to send objects with id/key, name, and itemsCount before we can wire this -->
		<!-- <BusinessTextButtonFilter
			v-if="hasBrands"
			:items="menuFilters.brands"
			filter-type="brand"
		/> -->
		<BusinessTextButtonFilter
			v-if="hasSubcategories"
			:items="menuFilters.subCategories"
			:total-count="totalItemsCount"
			filter-type="subCategory"
		/>
		<BusinessPriceFilter
			v-if="hasPrices"
			name="set-price-filter"
			:inputs="menuFilters.prices"
		/>
	</div>
</template>

<script async>
// import BusinessMenuGeneticsFilter from '@/components/business/BusinessMenuGeneticsFilter.vue'
// import BusinessMenuWeightFilters from '@/components/business/BusinessMenuWeightFilters.vue'
import BusinessPriceFilter from '@/components/business/BusinessPriceFilter.vue'
import BusinessTextButtonFilter from '@/components/business/BusinessTextButtonFilter.vue'

export default {
	components: {
		// BusinessMenuWeightFilters,
		// BusinessMenuGeneticsFilter,
		BusinessPriceFilter,
		BusinessTextButtonFilter
	},
	props: {
		menuFilters: {
			type: Object,
			required: true
		},
		subCategoryName: {
			type: String,
			required: true
		},
		totalItemsCount: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			checkboxes: [],
			weights: [
				{
					value: '1.5g',
					unavailable: true
				},
				{
					value: '1/8oz',
					unavailable: false
				},
				{
					value: '1/4oz',
					unavailable: false
				},
				{
					value: '1/2oz',
					unavailable: false
				},
				{
					value: '1oz',
					unavailable: false
				},
				{
					value: '2oz',
					unavailable: false
				}
			],
			genetics: [
				'Sativa',
				'Indica',
				'Hybrid'
			],
			brands: [
				'brandOne', 'brandTwo', 'brandThree', 'brandFour', 'brandFive', 'brandSix'
			]
		}
	},
	computed: {
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		isMobile() {
			return this.mediaMatch === 'sm' || this.mediaMatch === 'xs'
		},
		buttonClasses() {
			return 'items-center border border-gray-300 rounded-lg bg-white shadow-sm h-20 w-20 md:h-9 md:w-9 mb-1 md:mb-0 md:mr-3'
		},
		labelClasses() {
			return 'text-center pointer-none text-xs md:text-base font-bold md:font-normal md:m-0'
		},
		hasBrands() {
			return !!this.menuFilters?.brands?.length
		},
		hasSubcategories() {
			return !!this.menuFilters?.subCategories?.length
		},
		hasPrices() {
			return !!this.menuFilters?.prices?.length
		}
	}
}
</script>
