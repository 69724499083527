<template>
	<div class="w-full text-sm">
		<WwCollapse
			show-arrow
			title-classes="flex items-center py-1"
			expanded
		>
			<template #title>
				<span class="flex font-bold uppercase">
					Sort By Price
				</span>
			</template>
			<template #content>
				<ul>
					<li
						v-for="input in inputs"
						:key="input.value"
						class="flex items-center group"
					>
						<input
							:id="`${input.label}-${input.value}`"
							v-model="selected"
							type="radio"
							:name="name"
							:value="input.key"
							:class="[
								'm-1 text-green-500 border-gray-400 rounded-full cursor-pointer focus:ring-green-500',
								selected === input.key ? 'group-hover:bg-green-500' : 'group-hover:bg-gray-200'
							]"
						>
						<label
							:for="`${input.label}-${input.value}`"
							class="px-2 py-1 rounded-full cursor-pointer group-hover:bg-gray-200"
						>
							{{ input.label }}
						</label>
					</li>
				</ul>
			</template>
		</WwCollapse>
	</div>
</template>

<script async>
import WwCollapse from '@/components/multiUse/WwCollapse.vue'

export default {
	components: {
		WwCollapse
	},
	props: {
		name: {
			type: String,
			required: true
		},
		inputs: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			selected: null
		}
	},
	watch: {
		selected() {
			if (this.selected) {
				this.$router.push({ query: { ...this.$route.query, price: this.selected } })
			}
		},
		$route() {
			if (!this.$route.query?.price) this.clearSelected()
			else this.setSelectedFromRouteParams()
		}
	},
	mounted() {
		this.setSelectedFromRouteParams()
	},
	methods: {
		clearSelected() {
			this.selected = null
		},
		setSelectedFromRouteParams() {
			this.selected = this.$route.query?.price || null
		}
	}
}
</script>
