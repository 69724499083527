import { render, staticRenderFns } from "./BusinessMenuFilters.vue?vue&type=template&id=88d7911e&"
import script from "./BusinessMenuFilters.vue?vue&type=script&async=true&lang=js&"
export * from "./BusinessMenuFilters.vue?vue&type=script&async=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports