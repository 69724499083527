<template>
	<WwCollapse
		show-arrow
		class="w-full text-sm"
		title-classes="flex items-center py-1"
		expanded
	>
		<template #title>
			<span class="flex font-bold uppercase">
				{{ $route?.query?.category }} {{ filterType }}
			</span>
		</template>
		<template #content>
			<ul>
				<li
					:key="firstItem.name"
					class="flex items-center"
				>
					<button
						class="px-2 py-1 text-left capitalize"
						:disabled="disableButton(firstItem)"
						:class="getTextStyle(firstItem)"
						@click="setItem('')"
					>
						{{ firstItem.name }} {{ getItemsCount(firstItem) }}
					</button>
				</li>
				<li
					v-for="item in secondThreeItems"
					:key="item.name"
					class="flex items-center"
				>
					<button
						class="px-2 py-1 text-left"
						:disabled="disableButton(item)"
						:class="getTextStyle(item)"
						@click="setItem(item.name)"
					>
						{{ item.name }} {{ getItemsCount(item) }}
					</button>
				</li>
				<li>
					<WwCollapse
						hide-title-on-expand
						title-classes="flex items-center"
						@expanded="setExpanded"
					>
						<template #title>
							<span
								v-if="isExpandable"
								class="flex items-center px-2 py-1 text-green-500 hover:text-purple-500"
							>
								View More
							</span>
						</template>
						<template #content>
							<ul>
								<li
									v-for="item in remainingItems"
									:key="item.name"
								>
									<button
										class="px-2 py-1 text-left"
										:disabled="disableButton(item)"
										:class="getTextStyle(item)"
										@click="setItem(item.name)"
									>
										{{ item.name }} {{ getItemsCount(item) }}
									</button>
								</li>
							</ul>
						</template>
						<template	#close>
							<span class="flex items-center px-2 py-1 text-green-500 hover:text-purple-500">
								View Less
							</span>
						</template>
					</WwCollapse>
				</li>
			</ul>
		</template>
	</WwCollapse>
</template>

<script async>
import WwCollapse from '@/components/multiUse/WwCollapse.vue'

export default {
	components: {
		WwCollapse
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		filterType: {
			type: String,
			required: true
		},
		totalCount: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			selected: '',
			seeAll: false
		}
	},
	computed: {
		sortedItems() {
			return [
				{
					name: `All ${this.$route?.query?.category}`,
					itemsCount: this.totalCount,
					id: this.$route?.query?.category,
					isAll: true
				},
				...Array.from(this.items).sort((a, b) => b.itemsCount - a.itemsCount).map((item, index) => {
					return typeof item === 'string' ? { id: index, name: item } : item
				})
			]
		},
		firstItem() {
			return this.sortedItems[0]
		},
		secondThreeItems() {
			return this.sortedItems.slice(1, 4)
		},
		remainingItems() {
			return this.sortedItems.slice(4)
		},
		isExpandable() {
			return this.remainingItems?.length
		},
		filterUrl() {
			return this.$route.query?.[this.filterType] || ''
		}
	},
	watch: {
		$route() {
			if (!this.filterUrl) this.selected = null
		}
	},
	mounted() {
		this.selected = this.filterUrl
	},
	methods: {
		setItem(value) {
			this.selected = value
			if (value) {
				this.$router.push({ query: { ...this.$route.query, [this.filterType]: this.selected } })
			} else {
				const queryObject = { ...this.$route.query }
				delete queryObject[this.filterType]
				this.$router.push({ query: queryObject })
			}
		},
		getTextStyle(value) {
			const hasNoItems = this.getItemsCount(value) === '(0)'
			const selected = value.name === this.filterUrl || value?.isAll && this.filterUrl === ''

			if (hasNoItems) return 'cursor-default text-gray-300 font-normal'
			if (selected) return 'text-green-500 font-bold hover:bg-gray-200 rounded-xl'

			return 'hover:bg-gray-200 rounded-xl'
		},
		setExpanded(value) {
			this.seeAll = value
		},
		getItemsCount(item) {
			if (typeof item.itemsCount === 'number') return `(${item.itemsCount})`
		},
		disableButton(item) {
			return this.getItemsCount(item) === '(0)'
		}
	}
}
</script>
